import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/Layout.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/Home.vue"),
      },

      {
        path: "/collegeComparison",
        name: "collegeComparison",
        component: () => import("../views/collegeComparison.vue"),
      },
      {
        path: "/collegeLibrary",
        name: "collegeLibrary",
        component: () => import("../views/CollegeLibrary.vue"),
      },
      {
        path: "/ceshi",
        name: "ceshi",
        component: () => import("../views/ceshi.vue"),
      },
      {
        path: "/Score",
        name: "Score",
        component: () => import("../views/Score.vue"),
      },
      {
        path: "/ceshi2",
        name: "ceshi2",
        component: () => import("../views/ceshi2.vue"),
      },
      {
        path: "/ceshi3",
        name: "ceshi3",
        component: () => import("../views/ceshi3.vue"),
      },
      {
        path: "/ceshi4",
        name: "ceshi4",
        component: () => import("../views/ceshi4.vue"),
      },
      {
        path: "/province/:provinceName",
        name: "ProvinceMap",
        component: () => import("../views/ProvinceMap.vue"), // 确保这个组件已正确引入
      },
      {
        path: "/school/:id",
        name: "SchoolDetails",
        component: () => import("../views/SchoolDetails.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
